.nav-bar {
  align-items: baseline;
  display: flex;
  font-family: 'Manrope', sans-serif;
  justify-content: space-between;
}

.nav-links {
  align-items: center;
  display: flex;
}

.nav-link {
  padding: 0 1em;
}

.nav-link:nth-child(2) {
  border-left: 0.1em black solid;
  border-right: 0.1em black solid;
}
