@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

:root { font-size: 3vh; }

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 1.5em;
}

#root {
  margin: 0 auto;
  max-width: 1024px;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0.4em 0;
}

h1 { font-size: 2.8rem; }
h2 { font-size: 2.4rem; }

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

button,
input { font-size: 1.2rem; }

.no-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.no-wrap {
  white-space: nowrap;
}

@media screen and (min-height: 640px) {
  :root { font-size: 20px; }
}

@media (orientation: portrait) {
  :root { font-size: 3vw; }
}
